<template>
  <a-drawer title="復元" :width="720" :visible="visible" :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }" @close="onClose">

    <a-form layout="horizontal" autocomplete="off">
      <a-tree v-model:checkedKeys="checkedKeys" default-expand-all checkable
        :height="400" :tree-data="objectList">
      </a-tree>

      <br>

      <a-space wrap>
        <!-- TODO:ボタンの左寄せ -->
        <a-button @click="onDeselect">
          選択解除
        </a-button>
        <!-- TODO:ボタンの右寄せ -->
        <div v-if="isValid">
          <a-button @click="onRestore">
            復元
          </a-button>
        </div>
        <div v-else>
          <a-button disabled>復元</a-button>
        </div>
      </a-space>
      <br>
      <br>
      <a-form autocomplete="off">
        <a-form-item label="復元日数" name="restore_days" :rules="[{ validator: checkRestoreDays, trigger: 'change' }]">
          <a-input v-model:value="restore_days" allowClear placeholder="" style="width: 120px"/>
        </a-form-item>
      </a-form>
      <!-- TODO:ボタンの右寄せ？ -->
      <a-button @click="onClose">キャンセル</a-button>

    </a-form>
  </a-drawer>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { notification } from 'ant-design-vue';
import useRestoreRecord from '@/composable/useRestoreRecord'

export default defineComponent({
  components: {
  },
  setup() {
    const visible = ref(false);
    const objectList = ref([])
    const checkedKeys = ref([])
    const allFiles = {}

    const restore_days = ref(null)

    const showDrawer = (files) => {
      Array.from(files).map(
        (file) => {
          const key = file.key;
          const treeNode = {
            title: key,
            key,
          };
          allFiles[key] = file
          objectList.value.push(treeNode);
          checkedKeys.value.push(key)
        }
      )
      visible.value = true;
    };

    const onClose = () => {
      objectList.value.splice(0)
      checkedKeys.value.splice(0)
      visible.value = false;
    };

    const onDeselect = () => {
      checkedKeys.value.splice(0);
    }

    const onRestore = () => {
      const toRestoreFiles = []
      Array.from(checkedKeys.value).map(
        (key) => {
          toRestoreFiles.push(allFiles[key])
        }
      )
      useRestoreRecord(toRestoreFiles, restore_days.value)
        .then(() => {
          notifyRestored()

          Array.from(checkedKeys.value).map(
            (key) => {
              objectList.value.forEach((object, index) => {
                if (key == object.key) {
                  objectList.value.splice(index, 1)
                }
              })
            }
          )
          checkedKeys.value.splice(0)
        })
        .catch(error => {
          notifyGeneralError(error);
        });
    }

    let checkRestoreDays = async (_rule, value) => {
            if (value) {
                // NOP、ダミーチェック
            }
            if (!restore_days.value.match(/^[0-9]+$/)) {
                return Promise.reject('正の整数値で入力してください');
            }

            return Promise.resolve();
        };

    const notifyRestored = () => {
      notification.open({
        message: '選択したファイルの復元を開始しました',
        duration: 2,
      });
    };

    const notifyGeneralError = (error) => {
      console.log(error)
      notification.open({
        message: 'エラーが発生しました',
        duration: 10,
        style: {
          width: '600px',
          marginLeft: `${335 - 600}px`,
          color: '#FF0000',
        },
      });
    };

    return {
      visible,
      showDrawer,
      onClose,

      onDeselect,
      onRestore,

      checkRestoreDays,

      checkedKeys,
      objectList,

      restore_days,
    };
  },
  methods: {
    onPutRestoreDays() {
      this.isValid = this.restore_days.match(/^[0-9]+$/)
    }
  },
  data() {
    return {
      isValid: false
    }
  },
  watch: {
    "restore_days": function() {
      this.onPutRestoreDays();
    }
  }
});
</script>
