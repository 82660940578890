import { Storage, Auth } from 'aws-amplify';
import awss3config from '../aws-s3-config.json'
const { S3Client, HeadObjectCommand } = require("@aws-sdk/client-s3");

async function useListS3Files(s3_path, type = "None") {

    // console.log('useListS3Files:', s3_path)
 
    var result = ''
    await Storage.list(s3_path + '/') // for listing ALL files without prefix, pass '' instead
        .then((results) => {
            console.log(results.results)
            result = results.results;
        })
        .catch((err) => {
            console.log(err)
            return err;
        });

    if (type == "None") {
        return result;
    }

    const s3Client = new S3Client({
        region: awss3config["region"],
        credentials: await Auth.currentCredentials()
    });

    const filteredResult = [];

    await Promise.all(
        result.map(async (obj) => {
            const param = {
                "Bucket": awss3config["bucket"],
                "Key": "public/" + obj.key
            };
            try {
                const response = await s3Client.send(new HeadObjectCommand(param))
                if (isInclude(response, type)) {
                        filteredResult.push(obj)
                }
            }
            catch(err) {
                console.log(err)
                return err;
            }
        })
    )
    return filteredResult;
}

function isInclude(response, type) {
    if (type == "Restore") {
        return (response["StorageClass"] == "DEEP_ARCHIVE" && !("Restore" in response))
    } else if (type == "Download") {
        return ( !("StorageClass" in response) || ("Restore" in response && response["Restore"].startsWith("ongoing-request=\"false\"")))
    }
    return false
}

export default useListS3Files;