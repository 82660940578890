import { Storage } from 'aws-amplify';

async function useUploadDataset(upload_to, title, entries, 
    start_date,
    customer,
    model,
    purpose,
    old_path,
    username,
    data_type){

    const fileEntries = [];
    await Promise.all(
        entries.map(entry => scanFiles(entry, fileEntries))
    );

    var tag = "start_date=" + start_date
            + "&" + "customer:model=" + customer + ":" + model
            + "&" + "purpose=" + purpose
            + "&" + "title=" + title
            + "&" + "old_path=" + old_path
            + "&" + "updated_by=" + username
            + "&" + "data_type=" + data_type
            + "&" + "camera_type=" + "" // TODO set by UI input when UI is implemented

    return Promise.all(
        Array.from(fileEntries).map(
            (fileEntry) => new Promise((resolve, reject) => {
                fileEntry.file(file => {
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(file);

                    reader.onload = () => {
                        let filePath = upload_to + "/" + title + fileEntry.fullPath;

                        console.debug('useUploadDataset:', filePath)

                        Storage.put(filePath, file,
                            {
                                progressCallback(progress) {
                                    // console.debug(`Uploaded: ${progress.loaded}/${progress.total}`);

                                    // 上位へ進捗を通知する
                                    const event = new CustomEvent('upload-progress', {
                                        detail: {
                                            name: file.name,
                                            loaded: progress.loaded,
                                            total: progress.total
                                        }
                                    })
                                    window.dispatchEvent(event);
                                },
                                tagging: tag
                            }
                        )
                            .then(result => {
                                console.log(result);
                                resolve(result);
                            })
                            .catch(err => {
                                console.log(err);
                                reject(err);
                            });
                    };
                })
            })
        )
    );

}


async function scanFiles(entry, tmpObject) {
    switch (true) {
        case (entry.isDirectory): {
            // console.log("useUploadDataset() check1-1");
            const entryReader = entry.createReader();
            const entries = await new Promise(resolve => {
                entryReader.readEntries(entries => resolve(entries));
            });
            await Promise.all(entries.map(entry => scanFiles(entry, tmpObject)));
            break;
        }
        case (entry.isFile): {
            tmpObject.push(entry);
            break;
        }
    }
}

export default useUploadDataset;