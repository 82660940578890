<template>
  <a-table :dataSource="dataSource" :columns="columns" :pagination="false" bordered>
    <!-- 日付のフィルタ設定ここから -->
    <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
      <div style="padding: 8px">
        <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
        <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
          @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
          <template #icon>
            <SearchOutlined />
          </template>
          検索
        </a-button>
        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
          リセット
        </a-button>
      </div>
    </template>
    <template #customFilterIcon="{ filtered }">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
    </template>
    <!-- 日付のフィルタ設定ここまで -->

    <template #bodyCell="{ column, text, record }">
      <!-- 認可状態に応じたアクション列の表示切替ここから -->
      <template v-if="column.key === 'action'">
        <div v-if="is_authorized">
          <span v-if="editableData[record.id]">
            <div class="editable-row-operations">
              <a @click="onSave(record.id, record.s3_path)">保存</a>
              <a @click="cancel(record.id)">キャンセル</a>
            </div>
          </span>
          <span v-else>
            <div class="editable-row-operations">
              <a-space>
                <div v-if="record.data_state !== '復元中'">
                  <a @click="edit(record.id)">編集</a>
                </div>
                <div v-else>
                  <a disabled>編集</a>
                </div>
              </a-space>
              <a-space>
                <div v-if="record.data_state !== '復元中'">
                  <a @click="onSelectDownloadFile(record.s3_path)">DL</a>
                  <SelectDownloadFileDrawer ref="select_download_file_drawer" />
                </div>
                <div v-else>
                  <a disabled>DL</a>
                </div>
              </a-space>
              <a-space>
                <div v-if="record.data_state !== '復元中'">
                  <a @click="onSelectDeleteFile(record.s3_path)">削除</a>
                  <SelectDeleteFileDrawer ref="select_delete_file_drawer" />
                </div>
                <div v-else>
                  <a disabled>削除</a>
                </div>
              </a-space>
              <a-space>
                <div v-if="record.data_state !== '復元中'">
                  <a @click="onSelectRestoreFile(record.s3_path)">復元</a>
                  <SelectRestoreFileDrawer ref="select_restore_file_drawer" />
                </div>
                <div v-else>
                  <a disabled>復元</a>
                </div>
              </a-space>
            </div>
          </span>
        </div>
        <div v-else>
          <a-space>
            <div v-if="record.data_state !== '復元中'">
              <a @click="onSelectDownloadFile(record.s3_path)">DL</a>
              <SelectDownloadFileDrawer ref="select_download_file_drawer" />
            </div>
            <div v-else>
              <a disabled>DL</a>
            </div>
          </a-space>
        </div>
      </template>
      <!-- 認可状態に応じたアクション列の表示切替ここまで -->
      <template v-else-if="column.key !== 'data_type' && column.key !== 'data_state'">
        <div>
          <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][column.dataIndex]" />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { defineComponent, reactive, ref, inject, toRefs } from 'vue';
import { notification } from 'ant-design-vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import useUpdateRecord from '@/composable/useUpdateRecord'
import useListS3Files from '@/composable/useListS3Files';
import SelectDeleteFileDrawer from '../selectfile/SelectDeleteFileDrawer.vue';
import SelectDownloadFileDrawer from '../selectfile/SelectDownloadFileDrawer.vue';
import SelectRestoreFileDrawer from '../selectfile/SelectRestoreFileDrawer.vue';

export default defineComponent({
  components: {
    SearchOutlined,
    SelectDeleteFileDrawer,
    SelectDownloadFileDrawer,
    SelectRestoreFileDrawer
  },
  setup() {
    // 上位コンポーネントから共有された変数
    const is_authorized = inject('is_authorized')   // 認可状態
    const id_token = inject('id_token')

    const columns = [
      {
        title: '日付',
        dataIndex: 'start_date',
        key: 'start_date',
        sorter: {
          compare: (a, b) => a.start_date - b.start_date,
        },
        customFilterDropdown: true,
        onFilter: (value, record) => record.start_date.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: visible => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        },
      },
      {
        title: '機種',
        dataIndex: 'model',
        key: 'model',
      },
      {
        title: '目的・機能',
        dataIndex: 'purpose',
        key: 'purpose',
      },
      {
        title: '作成者',
        dataIndex: 'uploader',
        key: 'uploader',
      },
      {
        title: '旧パス',
        dataIndex: 'old_path',
        key: 'old_path',
      },
      {
        title: 'Hot/Cold',
        dataIndex: 'data_type',
        key: 'data_type',
      },
      {
        title: 'アクション',
        dataIndex: 'action',
        key: 'action',
      },
      {
        title: '状態',
        dataIndex: 'data_state',
        key: 'data_state',
      }
    ];

    const data = [];
    const dataSource = ref(data);

    const state = reactive({
      searchText: '',
      searchedColumn: '',
    });
    const searchInput = ref();

    const editableData = reactive({});
    const edit = id => {
      editableData[id] = cloneDeep(dataSource.value.filter(item => id === item.id)[0]);
    };
    const onSave = (id, s3_path) => {
      Object.assign(dataSource.value.filter(item => id === item.id)[0], editableData[id]);
      useListS3Files(s3_path)
        .then((responce) => {
          if (responce.length) {
            const tag_data_type = editableData[id]['data_type'] === "Hot" ? 0 :
                                  editableData[id]['data_type'] === "Cold" ? 1 :
                                  0
            useUpdateRecord(
              responce,
              editableData[id]['start_date'],
              editableData[id]['customer'],
              editableData[id]['model'],
              editableData[id]['purpose'],
              editableData[id]['uploader'],
              editableData[id]['old_path'],
              editableData[id]['title'],
              tag_data_type,
            )
              .then(() => {
                delete editableData[id];
              })
              .catch(error => {
                notifyGeneralError(error);
              });
          }
        })
        .catch((error) => {
          console.log(error)
          notifyGeneralError()
        });

    };
    const cancel = id => {
      delete editableData[id];
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };
    const handleReset = clearFilters => {
      clearFilters({
        confirm: true,
      });
      state.searchText = '';
    };

    const notifyDownloadStart = () => {
      notification.open({
        message: 'ダウンロードを開始します',
        duration: 2,
      });
    };

    const notifyNoDeletableData = () => {
      notification.open({
        message: '削除可能なデータが見つかりませんでした',
        duration: 2,
      });
    };

    const notifyNoDownloadableData = () => {
      notification.open({
        message: 'ダウンロード可能なデータが見つかりませんでした',
        duration: 2,
      });
    };

    const notifyNoRestorableData = () => {
      notification.open({
        message: '復元可能なデータが見つかりませんでした',
        duration: 2,
      });
    };

    const notifyGeneralError = () => {
      notification.open({
        message: 'エラーが発生しました',
        //description: error,
        duration: 10,  // 自動消去しない
        style: {
          width: '600px',
          marginLeft: `${335 - 600}px`,
          color: '#FF0000',
        },
      });
    };

    return {
      is_authorized,
      id_token,

      dataSource,
      columns,
      editableData,
      edit,
      onSave,
      cancel,

      handleSearch,
      handleReset,

      notifyDownloadStart,
      notifyNoDeletableData,
      notifyNoDownloadableData,
      notifyNoRestorableData,
      notifyGeneralError,

      searchInput,
      ...toRefs(state),
    };
  },

  methods: {
    updateDataset(dataset) {
      Array.from(dataset).map((data) => {
        data['data_type'] = data['data_type'] === 0 ? "Hot" :
                            data['data_type'] === 1 ? "Cold" :
                            "Unknown"
        data['data_state'] = data['data_state'] === 0 ? "アクティブ" :
                              data['data_state'] === 1 ? "アーカイブ" :
                              data['data_state'] === 2 ? "復元中" :
                              data['data_state'] === 3 ? "復元済" :
                              "Unknown"
    })
      this.dataSource = dataset;
    },
    async onSelectDeleteFile(s3_path) {
      await (this.is_authorized)   // $refsが参照可能となるまで待機
      useListS3Files(s3_path)
        .then((response) => {
          if (response.length) {
            this.$refs.select_delete_file_drawer.showDrawer(response);
          }
          else {
            this.notifyNoDeletableData()
          }
        })
        .catch(() => {
          this.notifyGeneralError()
        });
    },
    async onSelectDownloadFile(s3_path){
      await (this.is_authorized)   // $refsが参照可能となるまで待機
      useListS3Files(s3_path, "Download")
        .then((response) => {
          if (response.length) {
            this.$refs.select_download_file_drawer.showDrawer(response);
          }
          else {
            this.notifyNoDownloadableData()
          }
        })
        .catch(() => {
          this.notifyGeneralError()
        });
    },
    async onSelectRestoreFile(s3_path){
      await (this.is_authorized)   // $refsが参照可能となるまで待機
      useListS3Files(s3_path, "Restore")
        .then((response) => {
          if (response.length) {
            this.$refs.select_restore_file_drawer.showDrawer(response);
          }
          else {
            this.notifyNoRestorableData()
          }
        })
        .catch(() => {
          this.notifyGeneralError()
        });
    }
  },

  mounted() {
    // テスト用初期データ
    // for (let i = 0; i < 20; i++) {
    //   this.dataSource.push({
    //     id: i.toString(),
    //     date: `2023050${i}`,
    //     model: `機種 ${i}`,
    //     purpose: `検知 ${i}`,
    //     creator: `${i}さん`,
    //     prev_path: `フォルダ ${i}`,
    //   });
    // }
  },

});
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
</style>