<template>
    <a-typography-text>アップロード進捗状況 ( {{ Math.round(total_uploaded_size / 1024 / 1024).toLocaleString() }}[MB] / {{
        Math.round(total_uploading_size / 1024 / 1024).toLocaleString() }}[MB]
        )</a-typography-text>
    <a-progress :percent="total_percent" />
</template>

<script setup>
import { ref } from 'vue';

// ファイル毎のアップロード進捗を保持する連想配列
let progress_info = {}

// 全体進捗、アップロード済みサイズ、アップロード予定サイズ
let total_percent = ref(0)
let total_uploaded_size = ref(0)
let total_uploading_size = ref(0)

// 外部公開用関数のため、本ファイル内での未使用の警告を無効化する
// eslint-disable-next-line
const onProgessUpdated = (progress) => {
    // console.debug('[FileUploadProgressBar]onProgessUpdated:', progress)

    // アップロード完了時には1000が通知される模様なので無視する
    if (progress.loaded != 1000 && progress.total != 1000) {
        progress_info[progress.name] = progress

        total_uploaded_size.value = 0
        total_uploading_size.value = 0

        Object.keys(progress_info).forEach(function (key) {
            total_uploaded_size.value += progress_info[key].loaded
            total_uploading_size.value += progress_info[key].total
        });

        total_percent.value = Math.round((total_uploaded_size.value / total_uploading_size.value) * 100)
    }
}

// defineExposeが未定義の警告を無効化する
// eslint-disable-next-line no-undef
defineExpose({
    onProgessUpdated
});
</script>
