import { Auth } from 'aws-amplify'
import awss3config from '../aws-s3-config.json'
const { S3Client, RestoreObjectCommand } = require("@aws-sdk/client-s3");

async function useRestoreRecord(files, days) {

    const s3Client = new S3Client({
        region: awss3config["region"],
        credentials: await Auth.currentCredentials()
    });

    return Promise.all(
        Array.from(files).map(
            (file) =>
                new Promise((resolve, reject) => {
                    try {
                        const params = {
                            "Bucket": awss3config["bucket"],
                            "Key": "public/" + file.key,
                            "RestoreRequest": {
                                "Days": days,
                                "GlacierJobParameters": {
                                    "Tier": "Standard"
                                }
                            }
                        };
                        s3Client.send(new RestoreObjectCommand(params));
                        resolve()
                    } catch (error) {
                        reject(error)
                    }
                }
            )
        )
    );
}

export default useRestoreRecord;