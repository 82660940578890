import { Auth } from 'aws-amplify'
import awss3config from '../aws-s3-config.json'
const { S3Client, PutObjectTaggingCommand } = require("@aws-sdk/client-s3");

async function useUpdateRecord(files, start_date, customer, model, purpose, uploader, old_path, title, data_type) {

    // console.log('useUpdateRecord:', id, ',', start_date, ',', model, ',', purpose, ',', uploader, ',', old_path)
    const s3Client = new S3Client({
        region: awss3config["region"],
        credentials: await Auth.currentCredentials()
    });
    const tag = [
        { Key: "start_date", Value: start_date },
        { Key: "customer:model", Value: customer + ":" + model },
        { Key: "purpose", Value: purpose },
        { Key: "old_path", Value: old_path },
        { Key: "updated_by", Value: uploader },
        { Key: "title", Value: title },
        { Key: "data_type", Value: data_type },
        { Key: "camera_type", Value: "" },
      ];

    console.log(tag)
    return Promise.all(
        Array.from(files).map(
          (file) =>
              new Promise((resolve, reject) => {
                try {
                    const params = {
                        Bucket: awss3config["bucket"],
                        Key: "public/" + file.key,
                        Tagging: {
                            TagSet: tag,
                        },
                    };
                    s3Client.send(new PutObjectTaggingCommand(params));
                    resolve()
                } catch (error) {
                    console.log(error)
                    reject(error)
                }
            })
        )
    );
}

export default useUpdateRecord;